/*eslint-disable no-useless-catch*/
// Importar dependencias
import ApiClient from "./ApiClient";

// Prefijo base para los tipos de peticiones
// Perdidas
const lossesUrl = "losses/";
// Ventas
const salesUrl = "sales/";
// Ingresos
const incomesUrl = "incomes/";

// Funcion para crear un ingreso
async function createIncome(data) {
  // Intentamos llevar a cabo una petición
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const newIncome = await ApiClient.post(incomesUrl, data);
    // Regresamos lo que contenga la constante
    return newIncome;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Función para obtener los ingresos de un negocio
async function getIncomeByBusinessId(businessId, page) {
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const incomes = await ApiClient.get(
      `${incomesUrl}businesses/${businessId}?page=${page}`
    );
    // Regresamos lo que contenga la constante
    return incomes.data;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Obtiene los ingresos de una sucursal
async function getIncomeByBranchId(branchId, page) {
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const incomes = await ApiClient.get(
      `${incomesUrl}branches/${branchId}?page=${page}`
    );

    // Regresamos lo que contenga la constante
    return incomes.data;
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Función para eliminar un ingreso
async function deleteIncome(incomeId) {
  // Intentamos llevar a cabo una petición
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const deletedIncome = await ApiClient.delete(`${incomesUrl}${incomeId}`);
    // Regresamos lo que contenga la constante
    return deletedIncome.data;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Función para crear una perdida
async function createLoss(data) {
  // Intentamos llevar a cabo una petición
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const newLoss = await ApiClient.post(lossesUrl, data);
    // Regresamos lo que contenga la constante
    return newLoss.data;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Función para crear una nueva venta
async function createSale(data) {
  // Intentamos llevar a cabo la petición
  try {
    // Creamos una constante, la cual contendra lo que devuelva la petición
    const newSale = await ApiClient.post(salesUrl, data);
    // Regresamos lo que contenga la constante en caso de que todo salga bien
    return newSale.data;
    // Si algo no sale bien
  } catch (err) {
    throw new Error(err.response.data.message);
  }
}

// función para actualizar el status de una venta
async function updateSaleStatus(id, data) {
  // Realizamos la petición
  try {
    const response = await ApiClient.patch(`${salesUrl}${id}/status`, data);

    return response;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
}

// Actualizar el estado de is_shareable
async function updateShareableStatus(saleId, isShareable) {
  try {
    // Actualizar estado
    const response = await ApiClient.patch(`${salesUrl}${saleId}/shareable`, {
      isShareable
    });

    return response;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
}

// Función para obtener los egresos de un negocio
async function getLossesByBusinessId(businessId, page) {
  // Intentamos llevar a cabo una petición
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const losses = await ApiClient.get(
      `${lossesUrl}business/${businessId}?page=${page}`
    );
    // Regresamos lo que contenga la constante
    return losses.data;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Obtiene los egresos de una sucursal
async function getLossesByBranchId(branchId, page) {
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const losses = await ApiClient.get(
      `${lossesUrl}branches/${branchId}?page=${page}`
    );
    // Regresamos lo que contenga la constante
    return losses.data;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Función para obtener las ventas de un negocio
async function getSalesByBusinessId(businessId, page, status) {
  // Intentamos llevar a cabo una petición
  try {
    // Almacenamos las ventas
    let sales = [];
    // Verificamos si se pedira un status
    if (status === "all") {
      sales = await ApiClient.get(
        `${salesUrl}businesses/${businessId}?page=${page}`
      );
    } else {
      // Creamos la constante que contendra lo que devuelva la petición
      sales = await ApiClient.get(
        `${salesUrl}businesses/${businessId}?page=${page}&status=${status}`
      );
    }
    // Regresamos lo que contenga la constante
    return sales.data;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

async function getSalesByCustomerId(customerId, page = 1, limit = 5) {
  try {
    // Obtener ventas
    const salesResult = await ApiClient.get(
      `${salesUrl}customers/${customerId}?page=${page}?limit=${limit}`
    );

    // Regresar datos
    return salesResult.data;
  } catch (err) {
    // Regresar error
    throw new Error(err.response.data.message);
  }
}

// Obtiene una venta compartible
async function getShareableSale(saleId) {
  try {
    // Obtener venta
    const response = await ApiClient.get(`${salesUrl}shared?saleId=${saleId}`);
    return response.data;
  } catch (err) {
    // Regresar el error
    throw new Error(err.response.data.message);
  }
}

// Función para obtener las ventas de una sucursal
async function getSalesByBranchId(branchId, page, status) {
  try {
    // Almacenamos las ventaa
    let sales = [];
    // Verificamos si no se ha seleccionado algún filtro
    if (status === "all") {
      sales = await ApiClient.get(
        `${salesUrl}branches/${branchId}?page=${page}`
      );
    } else {
      // Creamos la constante que contendra lo que devuelva la petición
      sales = await ApiClient.get(
        `${salesUrl}branches/${branchId}?page=${page}&status=${status}`
      );
    }

    // Regresamos lo que contenga la constante
    return sales.data;
  } catch (err) {
    // Regresar error
    throw new Error(err.response.data.message);
  }
}

// Función para eliminar un egreso
async function deleteLoss(lossId) {
  // Intentamos llevar a cabo una petición
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const deletedLoss = await ApiClient.delete(`${lossesUrl}${lossId}`);
    // Regresamos lo que contenga la constante
    return deletedLoss;
    // Si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Función para eliminar una venta
async function deleteSale(saleId) {
  // Intentamos llevar a cabo una petición
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const deletedSale = await ApiClient.delete(`${salesUrl}${saleId}`);
    // Regresamos lo que contenga la constante
    return deletedSale;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Obtener una venta por su id
async function getSaleById(saleId) {
  // Intentamos llevar a cabo una petición
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const sale = await ApiClient.get(`${salesUrl}${saleId}`);

    // Regresamos lo que contenga la constante
    return sale.data;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Actualiza una venta
async function updateSale(data) {
  // Intentamos llevar a cabo la petición
  try {
    // Creamos una constante, la cual contendra lo que devuelva la petición
    const newSale = await ApiClient.patch(salesUrl, data);
    // Regresamos lo que contenga la constante en caso de que todo salga bien
    return newSale.data;
    // Si algo no sale bien
  } catch (err) {
    throw new Error(err.response.data.message);
  }
}

export default {
  createLoss,
  createSale,
  createIncome,
  getIncomeByBusinessId,
  getLossesByBusinessId,
  getLossesByBranchId,
  getIncomeByBranchId,
  getSalesByBusinessId,
  getSalesByBranchId,
  getSalesByCustomerId,
  getShareableSale,
  updateSaleStatus,
  updateShareableStatus,
  deleteLoss,
  deleteSale,
  deleteIncome,
  getSaleById,
  updateSale
};
