const methods = [
  {
    key: "cash",
    displayName: "Efectivo"
  },
  {
    key: "credit_card",
    displayName: "Tarjeta de crédito"
  },
  {
    key: "debit_card",
    displayName: "Tarjeta de débito"
  },
  {
    key: "transfer",
    displayName: "Transferencia"
  },
  {
    key: "check",
    displayName: "Cheque"
  },
  {
    key: "on_account",
    displayName: "A cuenta"
  },
  {
    key: "other",
    displayName: "Otro"
  }
];

export default {
  methods: {
    getPaymentMethods() {
      return methods;
    },
    findPaymentMethod(key) {
      const foundMethod = methods.find(method => method.key === key);

      if (!foundMethod) {
        return "-";
      }

      return foundMethod.displayName;
    }
  }
};
