<template>
  <!-- Contenedor -->
  <v-row justify="center">
    <!-- Botón primario -->
    <v-col cols="12" v-if="showPrimaryButton">
      <!-- Botón para detonar dialogo -->
      <PrimaryButton @click="dialog = true">
        Registrar Cliente
      </PrimaryButton>
    </v-col>
    <!-- Botón secundario (opcional) -->
    <v-col cols="12" align="right" v-else>
      <!-- Botón para detonar dialogo -->
      <v-btn text color="secondary" @click="dialog = true">
        Registrar cliente
      </v-btn>
    </v-col>
    <!-- Dialogo -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <!-- Modal -->
      <v-card v-if="!isLoading">
        <!-- Título -->
        <v-card-title>
          <p class="headline font-weight-bold mb-0">Registra un cliente</p>
        </v-card-title>
        <!-- Formulario -->
        <v-card-text class="mb-0 pb-0">
          <v-container fluid class="pa-0">
            <p class="subtitle-2 text-hint mb-1 mt-2">DATOS REQUERIDOS</p>
            <v-row no-gutters>
              <!-- Nombre-->
              <v-flex xs12>
                <TextField
                  class="mb-3"
                  placeholder="Nombre(s)"
                  v-model="firstName"
                />
              </v-flex>
              <!-- Apellido-->
              <v-flex xs12>
                <TextField
                  class="mb-3"
                  placeholder="Apellidos"
                  v-model="lastName"
                />
              </v-flex>
              <p class="subtitle-2 text-hint mb-1 mt-2">DATOS OPCIONALES</p>
              <!-- Número de teléfono -->
              <v-flex xs12>
                <vue-tel-input
                  class="full-width mb-3"
                  v-model="phoneNumber"
                  inputId="phoneNumber"
                  enabledCountryCode
                  required
                  validCharactersOnly
                  @input="userPhoneNumberInput"
                  placeholder="Ingresa tu número de teléfono"
                ></vue-tel-input>
              </v-flex>
              <!--  Correo -->
              <v-flex xs12>
                <TextField
                  class="mb-3"
                  placeholder="Correo electrónico"
                  v-model="email"
                />
              </v-flex>
              <!--  Nombre del negocio-->
              <v-flex xs12>
                <TextField
                  class="mb-3"
                  placeholder="Nombre del negocio"
                  v-model="company"
                />
              </v-flex>
              <!-- Descripción -->
              <v-flex xs12>
                <TextField
                  class="mb-3"
                  placeholder="Descripción"
                  v-model="description"
                />
              </v-flex>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="reset">Cancelar</v-btn>
          <v-btn color="primary" text @click="createClient">Agregar</v-btn>
        </v-card-actions>
      </v-card>
      <!-- Tarjeta si está cargando -->
      <v-card v-else align="center" justify="center">
        <v-progress-circular class="mt-12 mb-4" indeterminate color="primary" />
        <v-card-text class="text-center pb-12">
          Estamos agregando al cliente
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Importar componentes
const TextField = () => import("@/components/TextField");
const PrimaryButton = () => import("@/components/PrimaryButton");
import { VueTelInput } from "vue-tel-input";

// Importar dependencias
import { mapGetters } from "vuex";

// Importamos servicios
import CustomersServices from "@/services/CustomersServices";

export default {
  components: {
    PrimaryButton,
    TextField,
    VueTelInput
  },
  data() {
    return {
      email: "",
      dialog: false,
      lastName: "",
      firstName: "",
      isLoading: false,
      phoneNumber: "",
      company: "",
      description: "",
      isClientPhoneNumberValid: false
    };
  },
  props: {
    showPrimaryButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(["getProfile"]),
    isEmailValid: function() {
      // eslint-disable-next-line no-useless-escape
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.email);
    }
  },
  methods: {
    checkFields: function() {
      return !!this.firstName && !!this.lastName;
    },
    userPhoneNumberInput: function(name, data) {
      this.isClientPhoneNumberValid = data.isValid;
    },
    reset: function() {
      // Vaciar los campos
      this.isLoading = false;
      this.dialog = false;
      this.isClientPhoneNumberValid = false;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phoneNumber = "";
      this.company = "";
      this.description = "";
    },
    createClient: async function() {
      // Verificar si los campos están completos
      if (!this.checkFields()) {
        this.$emit("client-error", "Rellena todos los campos");
        return;
      }

      // Verificar que el número de teléfono sea válido
      if (this.phoneNumber.length > 0 && !this.isClientPhoneNumberValid) {
        this.$emit("client-error", "El número de teléfono no es válido");
        return;
      }

      // Crear objeto base
      let client = {
        first_name: this.firstName,
        last_name: this.lastName
      };

      // Verificar si agregó un correo
      if (this.email) {
        // Verificar que el correo sea valido
        if (!this.isEmailValid) {
          this.$emit("client-error", "El correo no es válido");
          return;
        }
        client.email = this.email;
      }

      // Verificar si se agregó un teléfono
      if (this.phoneNumber) {
        // Crear objeto cliente
        client.phone_number = this.phoneNumber;
      }

      // Verificar si agregó una compañia
      if (this.company) {
        // Crear objeto cliente
        client.company = this.company;
      }

      // Verificar si agregó una descripción
      if (this.description) {
        // Crear objeto cliente
        client.description = this.description;
      }

      // Realizamos la petición para crear un cliente
      try {
        this.isLoading = true;
        client = await CustomersServices.addCustomer(client);

        // Enviar evento a mixpanel
        this.$mixpanel.track("new-customer", {
          distinct_id: this.getProfile.business_id
        });

        this.$emit("new-client", client);
        this.reset();
      } catch (err) {
        this.isLoading = false;
        // Emitir evento con el error
        this.$emit("client-error", err.message);
      }
    }
  }
};
</script>

<style scoped></style>
