/*eslint-disable no-useless-catch*/
// Importar dependencias
import ApiClient from "./ApiClient";

const productUrl = "products/";

// Funcion para obtener todos los productos de un negocio
async function getProductsByBusiness(page, query) {
  // intentar
  try {
    let productResult;

    //Generar peticion para obtener los productos
    productResult = await ApiClient.get(`${productUrl}business`, {
      params: {
        search: query,
        page
      }
    });

    //Sí la peticion funciona, regresa el arreglo
    return productResult.data;
  } catch (err) {
    // Regresa el error
    throw new Error(err.response.data.message);
  }
}

// Funcion para obtener varios productos por su id
async function getProductsById(productsId) {
  // Llamamos a la funcion para obtener uno por uno e iteramos en cada id
  let products = [];
  for (let i = 0; i < productsId.length; i++) {
    const product = productsId[i];

    // Obtener y guardar el producto
    const currentProduct = await getProductById(product);
    products.push(currentProduct);
  }
  return products;
}

// Funcion para obtener un producto por su Id
async function getProductById(productId) {
  // Intentamos hace la petición
  try {
    const product = await ApiClient.get(productUrl + productId);
    return product.data;
  } catch (err) {
    // Regresa el error
    throw new Error(err.response.data.message);
  }
}

// Función para crear un producto sin un negocio específico
async function createProduct(data) {
  // Intentamos
  try {
    // Mandamos la petición al API
    const product = await ApiClient.post(productUrl, data);
    // Regresamos el producto con los nuevos datos
    return product.data._id;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
}

// función para actualizar un producto
async function updateProdut(productId, data) {
  try {
    // generamos la petición a la API
    const product = await ApiClient.patch(productUrl + productId, data);
    return product;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
}
// Funcion para agregar una imagen
async function addImage(productId, photo) {
  // Generamos la petición
  try {
    const image = await ApiClient.post(
      `${productUrl}${productId}/image`,
      photo
    );
    return image;
  } catch (err) {
    // Regresamos el error si algo sale mal
    throw new Error(err.response.data.message);
  }
}

//  Función para eliminar un producto
async function deleteProduct(productId) {
  // Generamos la peticion
  try {
    const deletedProduct = await ApiClient.delete(productUrl + productId);
    return deletedProduct;
  } catch (err) {
    // Regresamos el error si algo sale mal
    throw new Error(err.response.data.message);
  }
}
export default {
  getProductsByBusiness,
  createProduct,
  updateProdut,
  addImage,
  deleteProduct,
  getProductsById,
  getProductById
};
