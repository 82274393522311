<template>
  <!-- contenedor -->
  <v-container class="fill-height" fluid>
    <!-- Slot para el spinner -->
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: "LoadingLayout"
};
</script>
