/*eslint-disable no-useless-catch*/
// Importar dependencias
import ApiClient from "./ApiClient";

const tablesUrl = "tables/";

// Funcion para obtener todas las mesas de un negocio
async function getTablesByBusiness(page) {
  // intentar
  try {
    let tablesResult;

    //Generar peticion para obtener los productos
    if (page) {
      tablesResult = await ApiClient.get(tablesUrl + "business?page=" + page);
    } else {
      tablesResult = await ApiClient.get(tablesUrl + "business");
    }

    //Sí la peticion funciona, regresa el arreglo
    return tablesResult.data;
  } catch (err) {
    // Regresa el error
    throw new Error(err.response.data.message);
  }
}

// Función para crear una mesa sin un negocio específico
async function createTable(data) {
  // Intentamos
  try {
    // Mandamos la petición al API
    const table = await ApiClient.post(tablesUrl, data);
    // Regresamos el producto con los nuevos datos
    return table.data._id;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
}

// Elimina una mesa
async function deleteTable(tableId) {
  // Intentamos llevar a cabo una petición
  try {
    // Creamos la constante que contendra lo que devuelva la petición
    const deletedTable = await ApiClient.delete(`${tablesUrl}${tableId}`);

    // Regresamos lo que contenga la constante
    return deletedTable;
    // si algo sale mal
  } catch (err) {
    // Regresamos el error
    throw new Error(err.response.data.message);
  }
}

// Get table by id
async function getTableById(tableId) {
  // Realizamos la petición
  try {
    const table = await ApiClient.get(`${tablesUrl}${tableId}`);
    // Regresamos el cliente
    return table.data;
  } catch (err) {
    // en caso de un error, lanzamos el error
    throw new Error(err.response.data.message);
  }
}

export default {
  getTablesByBusiness,
  createTable,
  deleteTable,
  getTableById
};
