const statuses = [
  {
    name: "Pendiente",
    type: "pending",
    color: "#FDD835"
  },
  {
    name: "En proceso",
    type: "processing",
    color: "#FFB300"
  },
  {
    name: "Completado",
    type: "completed",
    color: "#00E676"
  },
  {
    name: "Pagado",
    type: "paid",
    color: "#00E676"
  },
  {
    name: "Atascado",
    type: "stuck",
    color: "#757575"
  },
  {
    name: "Cancelado",
    type: "cancelled",
    color: "#E65100"
  },
  {
    name: "Sin cobrar",
    type: "missing_payment",
    color: "#E53935"
  }
];

const toysStatuses = [
  {
    name: "Crédito",
    type: "credit",
    color: "#FDD835"
  },
  {
    name: "A cuenta",
    type: "on_account",
    color: "#E65100"
  },
  {
    name: "Pagado",
    type: "paid",
    color: "#00E676"
  }
];

export default {
  methods: {
    getSaleStatusData(statusType) {
      for (const status of statuses) {
        if (status.type === statusType) {
          return status;
        }
      }
      return {
        name: "Desconocido",
        type: "unknown",
        color: "#404040"
      };
    },
    getStasuses(businessId) {
      if (businessId === "659445e4883837002676e90e") {
        return toysStatuses;
      }

      return statuses;
    }
  }
};
