// Importar dependencias
import ApiClient from "./ApiClient";

const branchesUrl = "branches/";

// Función para obtener una sucursal por su id
async function getBranch(branchId) {
  try {
    // Realizar petición al servidor
    const branchResult = await ApiClient.get(branchesUrl + branchId);
    return branchResult.data;
  } catch (err) {
    // Lanzar un nuevo error
    throw new Error(err.response.data.message);
  }
}

// Función para crear una sucursal
async function createBranch(data) {
  // Realizamos una petición para crear la nueva sucursal
  try {
    const branchRecord = await ApiClient.post(branchesUrl, data);
    // Regresamos los datos de la sucursal que se borrará
    return branchRecord;
  } catch (err) {
    // en caso de un error, lanzamos el error
    throw new Error(err.response.data.message);
  }
}

// Obtiene las sucursales de un negocio
async function getBranchesByBusinessId(businessId, page) {
  try {
    const branches = await ApiClient.get(
      `${branchesUrl}business/${businessId}?page=${page}`
    );
    return branches.data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
}

// Obtiene las sucursales en el perfil del usuario
async function getBranchesInUserProfile() {
  try {
    const branches = await ApiClient.get(`${branchesUrl}user`);
    return branches.data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
}

// Función para agregar sucursales a cada empleado
async function addEmployeeToBranches(employeeId, branchesIds) {
  let result = [];

  try {
    // Iterar por los ids de las sucursales
    for (let i = 0; i < branchesIds.length; i += 1) {
      // Agregamos la sucursal
      let resultItem = await ApiClient.post(
        `${branchesUrl}employee/${employeeId}/add-branch/${branchesIds[i]}`
      );

      // Agregamos al respuesta a un arreglo
      result.push(resultItem);
    }

    return result;
  } catch (err) {
    // Si algo sale mal, regresa el error
    throw new Error(err.response.data.message);
  }
}

// Función para agregar sucursales a cada empleado
async function removeEmployeeFromBranches(employeeId, branchesIds) {
  let result = [];

  try {
    // Iterar por los ids de las sucursales
    for (let i = 0; i < branchesIds.length; i += 1) {
      // Removemos la sucursal
      let resultItem = await ApiClient.post(
        `${branchesUrl}employee/${employeeId}/remove-branch/${branchesIds[i]}`
      );

      // Agregamos al respuesta a un arreglo
      result.push(resultItem);
    }

    return result;
  } catch (err) {
    // Si algo sale mal, regresa el error
    throw new Error(err.response.data.message);
  }
}

// Función para eliminar(Desactivar) una sucursal
async function deleteBranch(branchId) {
  try {
    let deletedBranch = await ApiClient.delete(`${branchesUrl}${branchId}`);
    return deletedBranch;
  } catch (err) {
    // Si algo sale mal, regresa el error
    throw new Error(err.response.data.message);
  }
}

// Función para actualizar una sucursal
async function updateBranchById(branchId, data) {
  // Generamos petición
  try {
    const updatedBranch = await ApiClient.patch(
      `${branchesUrl}${branchId}`,
      data
    );
    return updatedBranch;
  } catch (err) {
    // Si algo sale mal, regresa el error
    throw new Error(err.response.data.message);
  }
}

// Función para obtener el publicKey de algolia
async function getUserBranchesWithAlgoliaPublicKey() {
  // Generamos petición
  try {
    const publicKey = await ApiClient.get(`${branchesUrl}user-with-public-key`);
    return publicKey.data;
  } catch (err) {
    // Si algo sale mal, regresa el error
    throw new Error(err.response.data.message);
  }
}
export default {
  getBranch,
  createBranch,
  addEmployeeToBranches,
  removeEmployeeFromBranches,
  getBranchesByBusinessId,
  getBranchesInUserProfile,
  deleteBranch,
  updateBranchById,
  getUserBranchesWithAlgoliaPublicKey
};
