// Importar dependencias
import ApiClient from "./ApiClient";

const customersUrl = "customers/";

// Función para obtener todos los clientes de un negocio
async function getCustomersByBusinessId(page = 1) {
  // Realizamos la petición
  try {
    const customersResult = await ApiClient.get(
      `${customersUrl}business?page=${page}`
    );
    // Regresar datos de la petición
    return customersResult.data;
  } catch (err) {
    // en caso de un error, lanzamos el error
    throw new Error(err.response.data.message);
  }
}

// Función para obtener un cliente por su Id
async function getCustomerById(customerId) {
  // Realizamos la petición
  try {
    const client = await ApiClient.get(`${customersUrl}${customerId}`);
    // Regresamos el cliente
    return client.data;
  } catch (err) {
    // en caso de un error, lanzamos el error
    throw new Error(err.response.data.message);
  }
}

// Función para crear un cliente
async function addCustomer(data) {
  // Realizamos la petición
  try {
    const newCustomer = await ApiClient.post(customersUrl, data);
    // Regresamos el cliente
    return newCustomer;
  } catch (err) {
    // en caso de un error, lanzamos el error
    throw new Error(err.response.data.message);
  }
}

// Función para actualizar un cliente
async function updateCustomer(customerId, data) {
  // Realizamos la petición
  try {
    const updatedCustomer = await ApiClient.patch(
      customersUrl + customerId,
      data
    );
    // Regresamos el cliente
    return updatedCustomer;
  } catch (err) {
    // en caso de un error, lanzamos el error
    throw new Error(err.response.data.message);
  }
}

// Exportamos las funciones
export default {
  addCustomer,
  updateCustomer,
  getCustomerById,
  getCustomersByBusinessId
};
