<template>
  <!-- Fila única para todo el contenido -->
  <v-row class="fill-height" align-content="center" justify="center">
    <!-- Columna para el spinner -->
    <v-col cols="12" class="text-center">
      <v-progress-circular
        :size="55"
        :width="progressCircularWidth"
        indeterminate
        color="primary"
      />
    </v-col>
    <!-- Columna para el texto -->
    <v-col
      cols="12"
      class="subtitle-1 text-center font-weight-bold text--disabled"
    >
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CircularLoading",
  props: {
    progressCircularWidth: {
      type: Number,
      required: false,
      default: 6
    }
  }
};
</script>
